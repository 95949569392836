/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.repair-section-title {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  text-decoration: underline;
  font-size: 1.1em;
  color: #D22027;
  margin-bottom: 12px;
}
.repair-subsection-container {
  padding-left: 24px;
}
.repair-result-section {
  padding: 12px 24px;
  border: 1px solid #52c41a;
  background-color: #fafafa;
  border-radius: 4px;
  margin-bottom: 32px;
}
.mobile .repair-result-section {
  padding: 12px;
}
