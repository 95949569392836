/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.service_job-collapse::v-deep > .ant-collapse-item > .ant-collapse-header {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 1.2em;
}
.service_job-collapse::v-deep > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra > .anticon {
  padding: 8px;
  margin: -8px;
}
.service_job-manage-btn {
  float: left;
}
.mobile .service_job-manage-btn {
  float: none;
  margin-top: 8px;
  text-align: left;
}
.files-component {
  margin-right: 2rem;
}
.files-upload {
  display: flex;
  flex-direction: row;
  margin-right: 2rem;
}
.service_job-repair-pane::v-deep .ant-collapse-content-box {
  background: #fff;
}
.service_job-firmware-pane::v-deep .ant-collapse-content-box {
  background: #e0f2f1;
}
.service_job-maintenance-pane::v-deep .ant-collapse-content-box {
  background: #feffe6;
}
.service_job-parts-pane::v-deep .ant-collapse-content-box {
  background: #e6f7ff;
}
.service_job-files-pane::v-deep .ant-collapse-content-box {
  background: #efebe9;
}
