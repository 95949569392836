/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.myselect-part-custom {
  display: flex;
  flex-wrap: nowrap;
}
.myselect-part-btn {
  display: block;
}
.myselect-part-input {
  width: 205px;
}
.mycard-image {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
