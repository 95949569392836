/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.service_repair_result-row > label {
  display: inline-block;
  color: #1890ff;
  margin-bottom: 8px;
}
.result-display {
  margin-bottom: 4px;
  margin-left: 24px;
  font-size: 2em;
  color: #1890ff;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
}
.reason-display {
  color: #8c8c8c;
  margin-left: 32px;
}
.reason-input {
  min-width: 275px;
}
.service_repair_result-input-block {
  display: inline-block;
}
