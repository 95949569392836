/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.service-part-list {
  height: 300px;
}
.part-card {
  display: flex;
  flex-flow: row;
  gap: 10px;
}
.mycard-image {
  width: 4%;
}
.part-card-details {
  width: 48%;
  display: flex;
  flex-flow: column;
}
.part-card-detail {
  display: flex;
  flex-flow: row;
}
.part-card-details-extend {
  width: 48%;
  display: flex;
  flex-flow: column;
  border-left: 2px solid #d9d9d9;
  padding-left: 5px;
}
.part-card-select-btn {
  align-self: center;
}
.primary-color-text {
  color: #14837B;
  font-weight: bold;
}
