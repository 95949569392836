/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.service-price-list-modal {
  width: 700px;
}
.service-price-list-modal div {
  font-size: 0.7rem;
}
.service-price-list-modal .claim-in-progress-error {
  display: flex;
  margin-top: 8px;
  font-size: 1.5em;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #D22027;
}
.service-price-list-modal .claim-remark {
  display: flex;
  margin-top: 8px;
  font-size: 1.5em;
  justify-content: center;
  align-items: center;
}
.tunjai-page-loading {
  min-height: 300px;
}
.tunjai-page-loading .ant-spin-blur {
  opacity: 0.4;
}
.tunjai-page-loading .ant-spin-dot i {
  background-color: #1890ff;
}
.tunjai-page-loading .ant-spin-text {
  font-size: 2em;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  margin-top: 14px;
  color: #1890ff;
}
.info {
  color: #1890ff;
}
.page-header {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  align-items: center;
  justify-content: space-between;
}
.service-no {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 30px;
}
.service-no-title {
  color: #14837B;
}
.form-detail {
  font-style: italic;
}
.modal-name {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.hg-logo {
  max-width: 25%;
  height: 40%;
}
.consent-form-header {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
}
.consent-form-title {
  text-align: center;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: #14837B;
}
.consent-form-page-num {
  text-align: right;
}
.service-detail-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
}
.service-detail-container .service-col {
  flex-basis: 50%;
  display: flex;
  flex-wrap: nowrap;
  min-width: 0;
  border: 1px solid #e8e8e8;
}
.service-detail-container .service-col > label {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  color: #14837B;
  min-width: 100px;
  flex-basis: 100px;
  background-color: #f5f5f5;
  padding: 4px 8px;
}
.service-detail-container .service-col > .service-val {
  flex-grow: 1;
  padding: 4px 8px;
  min-width: 0;
}
.service-detail-container .service-col > .service-val .service-val-row {
  margin-bottom: 2px;
}
.service-detail-container .service-col > .service-val .service-val-row:last-child {
  margin-bottom: 0;
}
.table-colum-bold-item {
  font-weight: 900;
}
.claim-row {
  display: flex;
  flex-direction: row-reverse;
}
.claim-row .claim-checkbox {
  margin-left: 8px;
}
.total-section {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 0px;
  margin-bottom: 16px;
}
.total-section .total-section-column {
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  margin-top: 8px;
  margin-bottom: 0px;
}
.total-section .total-section-column .total-section-row {
  display: flex;
  flex-direction: row-reverse;
  justify-content: right;
  align-items: right;
  margin-top: 8px;
}
.total-section .total-section-column .total-section-row .total-section-description {
  display: flex;
  flex-basis: 50%;
  position: relative;
  justify-content: right;
  align-items: right;
  margin-right: 8px;
}
.total-section .total-section-column .total-section-row .total-section-dash {
  display: flex;
  flex-basis: 2%;
  position: relative;
  justify-content: right;
  align-items: right;
  margin-right: 8px;
}
.total-section .total-section-column .total-section-row .total-section-price-claim {
  display: flex;
  flex-basis: 20%;
  position: relative;
  justify-content: right;
  align-items: right;
  margin-right: 8px;
}
.total-section .total-section-column .total-section-row .total-section-price {
  display: flex;
  flex-basis: 20%;
  position: relative;
  justify-content: left;
  align-items: left;
  margin-right: 8px;
}
.total-section .total-section-column .total-section-row .total-section-unit {
  display: flex;
  flex-basis: 8%;
  position: relative;
  justify-content: left;
  align-items: left;
  margin-right: 8px;
}
.signature {
  display: flex;
  position: relative;
  padding: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.signature .signature-item {
  display: flex;
  flex-basis: 33%;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-right: 8px;
}
.last-page-remark {
  display: flex;
  margin-top: 8px;
  justify-content: left;
  align-items: left;
}
.page-divider {
  display: flex;
  padding: 12px 16px;
  border-bottom: 1px dashed #14837B;
  position: relative;
}
.print-button {
  margin-top: 8px;
}
@media print {
  div {
    font-size: 0.7rem;
  }
  input[type='checkbox'] {
    display: none;
  }
  .page-divider {
    display: none;
  }
  .service-col > label {
    -webkit-print-color-adjust: exact;
  }
}
