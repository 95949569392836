/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.part-change-list {
  max-width: 1200px;
  margin-bottom: 24px;
}
.part-change-action {
  margin-top: 24px;
}
.part-change-empty {
  padding: 16px 0;
  color: #f5222d;
  background: #f5f5f5;
  text-align: center;
}
.part-change-header::v-deep {
  flex-wrap: wrap;
  color: #fff;
  display: none;
  background-color: #69c0ff;
}
.part-change-header::v-deep .part-change-col {
  margin-bottom: 0;
}
.part-change-header::v-deep label {
  color: #fff;
}
.part-change-header::v-deep .part-change-value {
  display: none;
}
@media (min-width: 992px) {
  .part-change-header::v-deep {
    display: flex;
  }
}
.part-change-body::v-deep {
  display: flex;
  flex-direction: column;
}
.part-change-body::v-deep .part-change-item:nth-child(even) {
  background-color: #e6f7ff;
}
@media (max-width: 991px) {
  .part-change-body::v-deep .part-change-item:nth-child(odd) {
    background-color: #f5f5f5;
  }
}
@media (min-width: 992px) {
  .part-change-body::v-deep .mobile-label {
    display: none;
  }
}
