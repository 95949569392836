/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.repair-checklist-input {
  width: 60px;
  margin-right: 4px;
  font-size: 0.9em;
}
.repair-checklist-input.wide {
  width: 100px;
}
.repair-inspection-checklist {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.repair-checklist-block {
  flex-grow: 1;
  flex-basis: 325px;
  min-width: 325px;
  max-width: 400px;
  margin-bottom: 24px;
  padding-right: 16px;
}
.mobile .repair-checklist-block {
  max-width: 100%;
  padding-right: 0;
  flex-basis: 275px;
  min-width: 275px;
}
.repair-checklist-block.repair-remark-block {
  max-width: 800px;
  min-height: 0;
}
.repair-remark {
  margin-top: 4px;
}
