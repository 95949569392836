/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.myselect-part-dropdown {
  width: 230px !important;
}
@media (max-width: 991px) {
  .myselect-part-dropdown {
    width: auto imp !important;
  }
}
