/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.firmware-description {
  color: #8c8c8c;
  margin-bottom: 24px;
}
.firmware-block {
  margin-bottom: 12px;
}
.firmware-block label {
  display: inline-block;
  color: #1890ff;
  width: 150px;
}
.firmware-block label::after {
  content: ' :';
}
.firmware-richinput {
  background-color: #fff;
  margin-top: 4px;
}
.firmware-block.view {
  font-size: 1.1em;
  border: 1px dashed #d9d9d9;
  padding: 12px 24px;
  max-width: 800px;
  background: #fff;
}
.firmware-block.view .text-value {
  color: #1890ff;
  text-decoration: none;
}
